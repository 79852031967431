<script setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  try {
    const response = await axios.get(`redirect/${route.params.slug}`);
    router.push({ name: 'survey-slug', params: { slug: response.data.slug } });
  } catch (error) {
    console.error('Error finding survey: ', error);
    router.push({ path: '/404' });
  }
});
</script>
