<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import DiceD20Icon from '@/components/icons/DiceD20Icon.vue';
import FileUploadIcon from '@/components/icons/FileUploadIcon.vue';
import axios from 'axios';

const route = useRoute();
const store = useStore();
const router = useRouter();

const surveyId = route.params.id;

const isEditing = ref(surveyId ? true : false);
const token = computed(() => store.state.token);
const isSpinning = ref(false);
const isSubmitting = ref(false);
const submissionError = ref('');
const removeImage = ref(0);

const survey = computed(() => store.state.currentSurvey);

const logoPath = computed(() => {
  if (survey.value && survey.value.logo) {
    return process.env.VUE_APP_IMAGE_URL + survey.value.logo.path;
  }
  return '';
});

const generateRandomSlug = (length = 6) => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
};

const randomizeSlug = () => {
  form.value.slug = generateRandomSlug(16);
  isSpinning.value = true;
  setTimeout(() => (isSpinning.value = false), 600);
};

const form = ref({
  title: '',
  slug: generateRandomSlug(16),
  logo: '',
  password: '',
  styles: {},
  anon: true,
});

const selectedFile = ref(null);

const handleFileChange = (event) => {
  if (survey.value?.logo) {
    survey.value.logo = null;
  }
  selectedFile.value = event.target.files[0];
  if (selectedFile.value && selectedFile.value.type.startsWith('image/')) {
    form.value.logo = URL.createObjectURL(selectedFile.value);
  } else {
    form.value.logo = '';
  }
};

const handleRemoveImage = () => {
  if (survey.value?.logo) {
    survey.value.logo = null;
  }
  removeImage.value = 1;
};

const handleSubmit = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  submissionError.value = '';

  const formData = new FormData();
  formData.append('title', form.value.title);
  formData.append('slug', form.value.slug);
  formData.append('anon', form.value.anon);
  formData.append('password', form.value.password || '');
  formData.append('remove_image', removeImage.value);
  Object.keys(form.value.styles).forEach((key) => {
    formData.append(`styles[${key}]`, form.value.styles[key]);
  });
  if (selectedFile.value) {
    formData.append('image', selectedFile.value);
  }

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  if (form.value.password == '') {
    form.value.password = null;
  }

  try {
    const url = isEditing.value ? `/surveys/${surveyId}` : '/surveys';
    const method = isEditing.value ? 'post' : 'post';

    const response = await axios({
      method: method,
      url: url,
      data: formData,
      headers: headers,
    });

    store.commit(
      isEditing.value ? 'UPDATE_SURVEY' : 'ADD_SURVEY',
      response.data
    );
    router.push({ name: 'survey-detail', params: { id: response.data.id } });
  } catch (error) {
    console.error('Error submitting form: ', error);
    submissionError.value =
      error.response?.data?.message || 'An unknown error occured';
  } finally {
    isSubmitting.value = false;
  }
};

const defaultStyles = {
  background_color: '#12122C',
  gradient_start: '#001594',
  gradient_stop: '#0AC8C7',
  text_color: '#FFFFFF',
  hover_text_color: '#FFFFFF',
};

onMounted(async () => {
  if (isEditing.value) {
    if (!survey.value || survey.value?.id != surveyId) {
      await store.dispatch('fetchSurvey', surveyId);
    }
    form.value = {
      title: survey.value.title,
      slug: survey.value.slug,
      password: survey.value.password,
      styles: {
        background_color:
          survey.value?.styles?.background_color ||
          defaultStyles.background_color,
        gradient_start:
          survey.value?.styles?.gradient_start || defaultStyles.gradient_start,
        gradient_stop:
          survey.value?.styles?.gradient_stop || defaultStyles.gradient_stop,
        text_color:
          survey.value?.styles?.text_color || defaultStyles.text_color,
        hover_text_color:
          survey.value?.styles?.hover_text_color ||
          defaultStyles.hover_text_color,
      },
    };
  } else {
    form.value.styles = {
      background_color: defaultStyles.background_color,
      gradient_start: defaultStyles.gradient_start,
      gradient_stop: defaultStyles.gradient_stop,
      text_color: defaultStyles.text_color,
      hover_text_color: defaultStyles.hover_text_color,
    };
  }
});

onUnmounted(() => {
  if (form.value.logo) {
    URL.revokeObjectURL(form.value.logo);
  }
});
</script>

<template>
  <section class="p-8">
    <form @submit.prevent="handleSubmit" class="max-w-7xl mx-auto px-2">
      <div class="flex flex-row justify-between mb-8">
        <h2 class="text-2xl font-[500]">
          {{ surveyId ? 'Edit' : 'Create' }} Survey
        </h2>
        <button
          class="bg-sd-blue-dark text-white text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-125 cursor-pointer"
        >
          Submit
        </button>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="bg-gray-50 border border-gray-100 shadow-sm px-8 py-8">
          <div class="mb-8 flex flex-row justify-between">
            <p class="text-sm font-[500] mb-1">General Options</p>
            <p class="text-xs">
              <span class="text-red-600 mr-0.5">*</span>Required
            </p>
          </div>
          <div class="mb-4 flex gap-4">
            <div class="flex-1">
              <label for="title" class="block text-sm mb-2 pl-0.5 text-gray-500"
                >Title<span class="text-red-600 ml-0.5">*</span></label
              >
              <input
                id="title"
                type="text"
                required
                v-model="form.title"
                placeholder="My Awesome Survey"
                class="w-full text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
              />
            </div>
            <div class="">
              <label for="title" class="block text-sm mb-2 pl-0.5 text-gray-500"
                >Anonymous<span class="text-red-600 ml-0.5">*</span></label
              >

              <label class="inline-flex items-center cursor-pointer h-[38px]">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  v-model="form.anon"
                />
                <div
                  class="relative w-10 h-5 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-[110%] rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-sd-blue-dark"
                ></div>
                <span class="ms-3 text-xs text-gray-500 capitalize">{{
                  form.anon
                }}</span>
              </label>
            </div>
          </div>
          <div class="mb-4">
            <label for="slug" class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Slug<span class="text-red-600 ml-0.5">*</span></label
            >
            <div class="relative">
              <input
                id="slug"
                type="text"
                required
                v-model="form.slug"
                class="w-full text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
              />
              <button
                @click="randomizeSlug"
                type="button"
                class="z-10 absolute right-4 top-2"
                title="Randomize Slug"
              >
                <DiceD20Icon class="w-5" :class="{ spin: isSpinning }" />
              </button>
            </div>
          </div>
          <div class="mb-4">
            <div
              class="flex flex-col sm:flex-row justify-between gap-1 px-0.5 mb-2 text-gray-500 sm:items-end"
            >
              <label for="password" class="text-sm">Password</label>
              <p class="text-xs">Leave Blank for No Password</p>
            </div>
            <input
              id="password"
              type="text"
              v-model="form.password"
              class="w-full text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
            />
          </div>
          <div>
            <p class="text-sm mb-2 pl-0.5 text-gray-500">Partner Logo</p>
            <img v-if="survey?.logo" :src="logoPath" class="max-h-12 mb-2" />
            <img
              v-if="form.logo"
              :src="form.logo"
              class="max-h-12 mb-2"
              alt="Image Preview"
            />
            <div class="flex flex-row gap-2">
              <label
                for="logo"
                class="bg-white text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95 cursor-pointer"
                ><FileUploadIcon /> Choose File</label
              >
              <button
                v-if="survey?.logo"
                @click="handleRemoveImage"
                type="button"
                class="bg-red-700 text-gray-50 text-xs px-4 py-2 focus-visible:outline-none shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95 cursor-pointer"
              >
                Remove Logo
              </button>
            </div>
            <input
              id="logo"
              type="file"
              rows="4"
              @change="handleFileChange"
              class="w-full text-xl px-2 py-1 rounded-sm bg-slate-50 hidden"
            />
          </div>
        </div>
        <div class="bg-gray-50 border border-gray-100 shadow-sm px-8 py-8">
          <div class="mb-8 flex flex-row justify-between">
            <p class="text-sm font-[500] mb-1">Style Options</p>
            <p class="text-xs">
              <span class="font-[500]">Format:</span> #367C2B
            </p>
          </div>
          <div class="mb-4">
            <label
              for="backgroundColor"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Button Background</label
            >
            <div class="flex flex-row gap-2">
              <input
                id="backgroundColor"
                type="text"
                v-model="form.styles.background_color"
                class="disabled:cursor-not-allowed disabled:bg-gray-300 w-24 text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
                pattern="#[a-fA-F0-9]{6}"
                disabled
              />
              <div
                :style="{ background: form.styles.background_color }"
                class="w-full h-[38px] rounded-lg shadow-inner"
              ></div>
            </div>
          </div>
          <div class="mb-4">
            <label
              for="gradientStart"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Button/Option Gradient Start</label
            >
            <div class="flex flex-row gap-2">
              <input
                id="gradientStart"
                type="text"
                v-model="form.styles.gradient_start"
                class="disabled:cursor-not-allowed disabled:bg-gray-300 w-24 text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
                pattern="#[a-fA-F0-9]{6}"
                disabled
              />
              <div
                :style="{ background: form.styles.gradient_start }"
                class="w-full h-[38px] rounded-lg shadow-inner"
              ></div>
            </div>
          </div>
          <div class="mb-4">
            <label
              for="gradientStop"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Button/Option Gradient Stop</label
            >
            <div class="flex flex-row gap-2">
              <input
                id="gradientStop"
                type="text"
                v-model="form.styles.gradient_stop"
                class="disabled:cursor-not-allowed disabled:bg-gray-300 w-24 text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
                pattern="#[a-fA-F0-9]{6}"
                disabled
              />
              <div
                :style="{ background: form.styles.gradient_stop }"
                class="w-full h-[38px] rounded-lg shadow-inner"
              ></div>
            </div>
          </div>
          <div class="mb-4">
            <label for="text" class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Button Text</label
            >
            <div class="flex flex-row gap-2">
              <input
                id="text"
                type="text"
                v-model="form.styles.text_color"
                class="disabled:cursor-not-allowed disabled:bg-gray-300 w-24 text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
                pattern="#[a-fA-F0-9]{6}"
                disabled
              />
              <div
                :style="{
                  background: form.styles.background_color,
                }"
                class="w-full h-[38px] rounded-lg shadow-inner p flex items-center justify-center"
              >
                <span :style="{ color: form.styles.text_color }" class="m-auto">
                  Am I Accessible?
                </span>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <label
              for="hoverText"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Button/Option Hover Text</label
            >
            <div class="flex flex-row gap-2">
              <input
                id="hoverText"
                type="text"
                v-model="form.styles.hover_text_color"
                class="disabled:cursor-not-allowed disabled:bg-gray-300 w-24 text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
                pattern="#[a-fA-F0-9]{6}"
                disabled
              />
              <div
                :style="{
                  background:
                    'linear-gradient( to right, ' +
                    form.styles.gradient_start +
                    ' , ' +
                    form.styles.gradient_stop +
                    ')',
                }"
                class="w-full h-[38px] rounded-lg shadow-inner p flex items-center justify-center"
              >
                <span
                  :style="{ color: form.styles.hover_text_color }"
                  class="m-auto"
                >
                  Am I Accessible?
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</template>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 600ms ease-in-out;
}
</style>
