<script setup>
import store from '@/store';
import { computed, onMounted, ref } from 'vue';
import SurveyTable from '@/components/ui/SurveyTable.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';
import CircleXMarkIcon from '@/components/icons/CircleXMarkIcon.vue';
// import FilterIcon from '@/components/icons/FilterIcon.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';

const surveys = computed(() => store.state.surveys);

const search = ref('');

const filteredSurveys = computed(() => {
  const searchTerm = search.value.trim().toLowerCase();

  if (!searchTerm) return surveys.value;

  return surveys.value.filter((survey) => {
    const titleWords = survey.title.toLowerCase().split(/\s+/);
    return titleWords.some((word) => word.startsWith(searchTerm));
  });
});

onMounted(() => {
  store.dispatch('fetchSurveys');
});
</script>

<template>
  <section class="p-8">
    <div class="max-w-7xl mx-auto px-2">
      <h2 class="text-2xl pl-0.5 font-[500]">Surveys</h2>
      <div class="py-6 flex flex-row justify-between font-[500]">
        <div class="relative">
          <input
            type="search"
            v-model="search"
            placeholder="Search"
            class="bg-white text-xs px-4 py-2 pr-8 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg focus-visible:brightness-95"
          />
          <transition name="fade">
            <SearchIcon
              v-if="!search"
              class="w-4 h-4 absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400"
            />
          </transition>

          <transition name="fade">
            <CircleXMarkIcon
              v-if="search"
              class="w-4 h-4 absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400 cursor-pointer"
              @click="search = ''"
            />
          </transition>
        </div>
        <div class="flex flex-row gap-2">
          <!-- <button
            class="bg-white text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95"
          >
            <FilterIcon /> Filters
          </button> -->
          <router-link
            to="/dashboard/survey/add"
            class="bg-sd-blue-dark text-white text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-125 cursor-pointer"
          >
            <PlusIcon /> Add Survey
          </router-link>
        </div>
      </div>
      <SurveyTable :surveys="filteredSurveys"></SurveyTable>
    </div>
  </section>
</template>

<style scoped>
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}
.fade-leave-active {
  transition: opacity 0.35s ease-in-out;
}
.fade-enter-active {
  transition: opacity 0.5s 0.4s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
