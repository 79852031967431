<script setup>
import { computed, onMounted, ref } from 'vue';
import Echo from 'laravel-echo';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import SurveyQuestionsGraph from '@/components/graphs/SurveyQuestionGraph.vue';
import SurveyQuestionBarGraph from '@/components/graphs/SurveyQuestionBarGraph.vue';
import SurveyOpenResponse from '@/components/ui/SurveyOpenResponse.vue';
import WarningIcon from '@/components/icons/WarningIcon.vue';

const store = useStore();
const route = useRoute();

const survey = ref(null);

const nonAnonResponses = computed(() => {
  if (survey.value?.anon) {
    return [];
  }

  // Group responses by name
  const groupedResponses = survey.value?.questions.flatMap((question) =>
    question.answer_options.flatMap((option) =>
      option.responses
        .filter((response) => response.name) // Only include responses with a name
        .map((response) => ({
          name: response.name,
          text: option.text,
          question: question.title,
          question_id: question.id,
          option_id: option.id,
        }))
    )
  );

  // Group responses by name
  if (groupedResponses) {
    return groupedResponses.reduce((acc, response) => {
      const existing = acc.find((item) => item.name === response.name);
      if (existing) {
        existing.responses.push(response);
      } else {
        acc.push({
          name: response.name,
          responses: [response],
        });
      }
      return acc;
    }, []);
  } else return [];
});

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'reverb',
  key: process.env.VUE_APP_REVERB_APP_KEY,
  wsHost: process.env.VUE_APP_REVERB_HOST,
  wsPort: process.env.VUE_APP_REVERB_PORT,
  wssPort: process.env.VUE_APP_REVERB_PORT,
  forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
  authEndpoint: process.env.VUE_APP_API_URL + '/broadcasting/auth',
  auth: {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  },
});

onMounted(async () => {
  await store.dispatch('fetchSurvey', route.params.id);

  survey.value = store.state.currentSurvey;

  window.Echo.private(`App.Models.Survey.${route.params.id}`).listen(
    'ResponseAdded',
    (event) => {
      survey.value = event.survey;
    }
  );
});
</script>

<template>
  <div>
    <div class="w-full px-8 py-4 border-b-2 border-gray-100 text-sd-blue-dark">
      <div
        class="max-w-7xl px-2 mx-auto flex flex-row gap-8 items-center justify-between"
      >
        <router-link :to="{ name: 'dashboard' }" class="inline-flex mt-0.5"
          ><span
            class="text-3xl font-[600] bg-gradient-to-r from-sd-blue-black via-sd-blue-dark to-sd-blue-light text-transparent bg-clip-text"
            >Surveys Direct</span
          ></router-link
        >
      </div>
    </div>
    <section class="p-8">
      <div class="max-w-7xl mx-auto px-2">
        <div class="mb-12 font-[500] text-2xl">
          <router-link
            class="cursor-pointer"
            :to="{ name: 'survey-detail', params: { id: survey?.id } }"
            >{{ survey?.title }}</router-link
          >
          <span
            v-if="!survey?.anon"
            class="flex gap-2 items-center text-sm text-gray-500 mt-2"
            ><WarningIcon class="text-red-700 mb-[1px]" /> Real-time updates are
            currently not available for non-anonymous surveys.</span
          >
        </div>
        <div class="grid grid-cols-1 gap-8" v-if="survey?.anon">
          <div
            v-for="(question, index) in survey?.questions"
            :key="question.id"
          >
            <SurveyQuestionsGraph
              v-if="question.type == 'single_selection'"
              :key="question.id"
              :question="question"
              :num="index"
            />
            <SurveyOpenResponse
              v-if="question.type == 'open_response'"
              :question="question"
            />
            <SurveyQuestionBarGraph
              v-if="question.type == 'ranked_voting'"
              :key="question.id"
              :question="question"
              :num="index"
            />
          </div>
        </div>
        <div class="overflow-auto" v-if="!survey?.anon">
          <table class="table-auto w-full">
            <thead class="bg-gray-100">
              <tr class="text-left text-xs rounded-lg">
                <th>Name</th>
                <th
                  v-for="(question, index) in survey?.questions"
                  :key="index"
                  class="question-header"
                  :title="question.title"
                >
                  {{ question.title }}
                </th>
              </tr>
            </thead>
            <tr
              v-for="(person, index) in nonAnonResponses"
              :key="index"
              class="border-b border-gray-300 last:border-b-0 text-sm"
            >
              <td class="capitalize">{{ person.name }}</td>
              <td v-for="(question, qIndex) in survey?.questions" :key="qIndex">
                <!-- Find the response for the current question -->
                <span
                  v-for="response in person.responses"
                  :key="response.option_id"
                >
                  <!-- Check if the response belongs to the current question -->
                  <span v-if="response.question_id === question.id">
                    {{ response.text }}
                  </span>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
th {
  padding: 0.5rem 1rem;
  font-weight: bold;
  max-width: 150px; /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

th:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
}

th:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}

th:hover::after {
  content: attr(title); /* Use the title attribute as the tooltip content */
  position: absolute;
  left: 0;
  bottom: 100%;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  font-size: 0.875rem;
  z-index: 10;
}

table thead {
  border-collapse: separate;
}

td {
  padding: 0.5rem 1rem;
}
</style>
