<script setup>
import axios from 'axios';
import { defineProps, ref, defineEmits, computed } from 'vue';
import LinkIcon from '../icons/LinkIcon.vue';
import SubmitButton from './SubmitButton.vue';

const props = defineProps({
  question: Object,
  total: Number,
  current: Number,
  styles: Object,
  name: String,
});

const emit = defineEmits(['answer']);

const selectedOption = ref(null);
const isSubmitting = ref(false);
const isHovering = ref(false);
const textResponseValue = ref('');
const errorMessage = ref('');
const localAnswerOptions = ref(
  props.question.answer_options.map((option) => ({
    ...option,
    rank: null,
  }))
);

const rankCheck = (index) => {
  const selected = localAnswerOptions.value[index];
  localAnswerOptions.value.forEach((option) => {
    if (option.rank == selected.rank && option != selected) {
      option.rank = null;
    }
  });
};

const ranked = computed(() => {
  return localAnswerOptions.value.every((option) => option.rank !== null);
});

const submitResponse = async () => {
  errorMessage.value = '';

  if (isSubmitting.value) return;

  isSubmitting.value = true;

  let payload = {};
  if (props.name && props.name.trim() !== '') {
    payload.name = props.name;
  }

  if (props.question.type == 'open_response') {
    payload.text = textResponseValue.value;
    try {
      await axios.post(
        `answer-options/response/${props.question.answer_options[0].id}`,
        payload
      );
      emit('answer');
    } catch (error) {
      console.error('Error creating response: ', error);
    } finally {
      isSubmitting.value = false;
    }
  } else if (props.question.type == 'ranked_voting') {
    payload.ranked_responses = localAnswerOptions.value
      .filter((option) => option.rank !== null)
      .map((option) => ({
        id: option.id,
        rank: option.rank,
      }));
    try {
      await axios.post(
        `answer-options/rank-response/${props.question.survey_id}`,
        payload
      );
      emit('answer');
    } catch (error) {
      console.error('Error creating response: ', error);
    } finally {
      isSubmitting.value = false;
    }
  } else {
    try {
      await axios.post(
        `answer-options/response/${selectedOption.value}`,
        payload
      );
      emit('answer');
    } catch (error) {
      errorMessage.value = error.response.data.message;
      console.error('Error creating response: ', error);
    } finally {
      isSubmitting.value = false;
    }
  }
};

const isLong = computed(() => {
  console.log(props.question.answer_options);
  let long = false;
  props.question.answer_options.forEach((option) => {
    if (option.text.length > 30) {
      long = true;
    }
  });
  return long;
});

// const buttonGradient = computed(() => {
//   if (!props.styles) return {};
//   return {
//     backgroundImage: `linear-gradient(to right, ${props.styles?.background_color} 50%, ${props.styles?.gradient_start} 50%, ${props.styles?.gradient_stop})`,
//     color: isHovering.value
//       ? props.styles?.hover_text_color
//       : props.styles?.text_color,
//   };
// });

// const checkedStyle = (optionId) => {
//   if (!props.styles) return {};
//   return selectedOption.value === optionId
//     ? {
//         // backgroundImage: `linear-gradient(to right, ${props.styles?.gradient_start}, ${props.styles?.gradient_stop})`,
//         // color: props.styles?.hover_text_color,
//       }
//     : {};
// };
</script>

<template>
  <form
    @submit.prevent="submitResponse"
    class="min-h-[484px] bg-white rounded-3xl m-4 p-4 md:p-20 max-w-3xl w-full flex justify-center flex-col gap-8 z-10"
  >
    <div class="text-sd-gray">
      <p class="py-2">{{ current }} / {{ total }}</p>
      <h2 class="font-bold text-2xl">{{ props.question.title }}</h2>
      <a
        v-if="props.question.link"
        :href="props.question.link"
        target="_blank"
        class="bg-white text-xs px-3 py-1 mt-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95"
      >
        <LinkIcon /> Visit Link
      </a>
    </div>
    <div
      class="grid grid-cols-1 gap-4"
      :class="isLong ? 'md:grid-cols-1' : 'md:grid-cols-2'"
      v-if="props.question.type == 'single_selection'"
    >
      <div
        v-for="(option, index) in props.question.answer_options"
        :key="option.id"
      >
        <input
          type="radio"
          :id="'question-' + index"
          name="question"
          :value="option.id"
          v-model="selectedOption"
          class="hidden peer"
        />
        <label
          :for="'question-' + index"
          class="w-full p-4 bg-gray-100 block rounded-lg cursor-pointer hover:brightness-90 transition-all duration-200 peer-checked:bg-[#AC047C] peer-checked:text-white h-full"
          >{{ option.text }}</label
        >
      </div>
    </div>
    <div v-if="props.question.type == 'open_response'">
      <label for="text_response" class="block text-sm text-sd-gray mb-2"
        >Respond Below</label
      >
      <textarea
        type="textarea"
        id="text_response"
        maxlength="256"
        v-model="textResponseValue"
        class="resize-none px-4 py-3 text-sm rounded-md border border-sd-gray bg-paper focus:outline-0 focus:border-gray-500 w-full shadow-md h-28 overflow-y-scroll"
      />
    </div>
    <div
      class="flex flex-col gap-4"
      v-if="props.question.type == 'ranked_voting'"
    >
      <div
        v-for="(option, index) in localAnswerOptions"
        :key="option.id"
        class="flex flex-row items-center justify-center gap-4"
      >
        <select
          class="px-4 py-3 border-sd-gray border-2 rounded-md text-sm h-[55px]"
          v-model="option.rank"
          @change="rankCheck(index)"
        >
          <option :value="null" selected>Select Rank</option>
          <option
            v-for="rank in localAnswerOptions.length"
            :key="rank"
            :value="rank"
          >
            {{ rank }}
          </option>
        </select>
        <div
          class="w-full p-4 bg-gray-100 block rounded-lg cursor-pointer hover:brightness-90 transition-all duration-200 peer-checked:bg-[#AC047C] peer-checked:text-white h-full"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
    <p v-if="errorMessage" class="text-xs text-red-600">{{ errorMessage }}</p>
    <div class="min-h-11">
      <SubmitButton
        v-show="selectedOption || textResponseValue.length || ranked"
        @mouseover="isHovering = true"
        @mouseout="isHovering = false"
        class="w-fit mx-auto"
      >
        Continue
      </SubmitButton>
    </div>
  </form>
</template>
