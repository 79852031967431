<script setup>
import { computed, defineProps, ref } from 'vue';
import SurveyTableRow from '@/components/ui/SurveyTableRow.vue';
import DeleteButton from '@/components/ui/DeleteButton.vue';
import AngleIcon from '../icons/AngleIcon.vue';
import axios from 'axios';
import store from '@/store';

const props = defineProps({
  surveys: Object,
});

const pageSize = ref(10);
const currentPage = ref(1);
const isSubmitting = ref(false);
const token = computed(() => store.state.token);

const selectedSurvey = ref(null);

const totalPages = computed(() => {
  return Math.ceil(props.surveys.length / pageSize.value);
});

const paginatedSurveys = computed(() => {
  const start = (currentPage.value - 1) * pageSize.value;
  const end = start + pageSize.value;
  return props.surveys.slice(start, end);
});

const showDelete = (survey) => {
  selectedSurvey.value = survey;
};

const handleDelete = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  try {
    let survey = selectedSurvey.value;
    await axios.delete(`/surveys/${survey.id}`, headers);
    store.commit('DELETE_SURVEY', survey);
    selectedSurvey.value = null;
  } catch (error) {
    console.error('Error Deleting Survey: ', error);
  } finally {
    isSubmitting.value = false;
  }
};
</script>

<template>
  <div class="overflow-auto">
    <table class="table-auto w-full">
      <thead class="bg-gray-100">
        <tr class="text-left text-xs rounded-lg">
          <th>Title</th>
          <th>Questions</th>
          <th>Responses</th>
          <th>Expiration</th>
          <th>Last Modified</th>
          <th class="w-0">Actions</th>
        </tr>
      </thead>
      <SurveyTableRow
        class="border-b border-gray-300 last:border-b-0"
        v-for="survey in paginatedSurveys"
        :key="survey.id"
        :survey="survey"
        @delete="showDelete(survey)"
      ></SurveyTableRow>
    </table>
    <p v-if="!surveys.length" class="text-center text-gray-400 text-sm py-8">
      No Surveys Matching Current Filters.
    </p>
    <div
      v-if="surveys.length"
      class="flex flex-row items-center justify-between py-6"
    >
      <button
        @click="currentPage--"
        :disabled="currentPage === 1"
        class="bg-gray-100 w-8 h-8 rounded-lg inline-flex items-center justify-center disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-400"
      >
        <AngleIcon class="-rotate-90" />
      </button>
      <p
        class="bg-gray-100 px-4 text-xs text-gray-600 h-8 rounded-lg inline-flex items-center"
      >
        Page {{ currentPage }} of {{ totalPages }}
      </p>
      <button
        @click="currentPage++"
        :disabled="currentPage === totalPages"
        class="bg-gray-100 w-8 h-8 rounded-lg inline-flex items-center justify-center disabled:bg-gray-200 disabled:cursor-not-allowed disabled:text-gray-400"
      >
        <AngleIcon class="rotate-90" />
      </button>
    </div>
    <div
      v-if="selectedSurvey"
      class="flex absolute inset-0 items-center justify-center"
    >
      <div class="absolute inset-0 bg-black opacity-45 z-10"></div>
      <div class="p-8 bg-white rounded-lg z-20 m-8 max-w-sm">
        <p class="text-red-900 text-xs font-[500]">Delete</p>
        <p class="text-lg">
          {{ selectedSurvey.title }}
        </p>
        <div class="mt-4">
          <p class="mb-4 text-gray-500 text-xs">
            Are you sure you want to delete {{ selectedSurvey.title }} and all
            associated questions, options, and response data?
          </p>
          <div class="flex gap-4 items-center">
            <DeleteButton @trigger="handleDelete"> Delete </DeleteButton>
            <button
              @click="selectedSurvey = null"
              type="button"
              class="bg-white text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
th {
  padding: 0.5rem 1rem;
  font-weight: 500;
}

th:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
  -moz-border-radius: 0.5rem 0 0 0.5rem;
  -webkit-border-radius: 0.5rem 0 0 0.5rem;
}

th:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
  -moz-border-radius: 0 0.5rem 0.5rem 0;
  -webkit-border-radius: 0 0.5rem 0.5rem 0;
}

table thead {
  border-collapse: separate;
}
</style>
