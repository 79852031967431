<script setup>
import { computed, defineProps, ref } from 'vue';
import { Doughnut } from 'vue-chartjs';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const props = defineProps({
  question: Object,
  num: Number,
});

const colors = ref(['#0184AB', '#02B4B5', '#18BEC6', '#30C1D8']);

const pastelColors = ref(['#0184AB', '#02B4B5', '#18BEC6', '#30C1D8']);

const doughnutChartRef = ref(null);
const hoveredIndex = ref(-1);

const options = computed(() => {
  return props.question.answer_options
    .slice()
    .sort((a, b) => b.responses_count - a.responses_count);
});

const totalCount = computed(() => {
  let total = 0;
  options.value.forEach((option) => {
    total = option.responses_count + total;
  });
  return total;
});

const data = computed(() => {
  const labels = options.value.map((option) => option.text);
  const data = options.value.map((option) => option.responses_count);

  return {
    labels,
    datasets: [
      {
        data: data,
        backgroundColor: colors.value,
        cutout: '64%',
        hoverOffset: 16,
        borderWidth: 1,
        clip: 16,
      },
    ],
  };
});

const chartOptions = {
  onHover: (event, chartElements) => {
    if (chartElements.length) {
      hoveredIndex.value = chartElements[0].index;
    } else {
      hoveredIndex.value = -1;
    }
  },
  layout: {
    padding: {
      top: 8,
      right: 8,
      bottom: 8,
      left: 8,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

function handleMouseEnter(index) {
  if (doughnutChartRef.value && doughnutChartRef.value.chart) {
    const chartInstance = doughnutChartRef.value.chart;
    chartInstance.setActiveElements([{ datasetIndex: 0, index }]);
    chartInstance.update();
  }
}

function handleMouseLeave() {
  if (doughnutChartRef.value && doughnutChartRef.value.chart) {
    const chartInstance = doughnutChartRef.value.chart;
    chartInstance.setActiveElements([]);
    chartInstance.update();
  }
}
</script>

<template>
  <div>
    <h2 class="text-lg mb-4 font-[500]">{{ question.title }}</h2>
    <div class="flex flex-row gap-16 items-start">
      <ul class="flex-1">
        <li
          v-for="(option, index) in options"
          :key="option.id"
          class="w-full bg-Paper mb-4 last:mb-0 py-2 rounded-md px-4 relative transition-all duration-200 hover:scale-[1.02] hover:shadow-md"
          :class="{ 'scale-[1.02] shadow-md': index === hoveredIndex }"
          @mouseenter="handleMouseEnter(index)"
          @mouseleave="handleMouseLeave"
        >
          <p class="z-20 relative flex flex-row justify-between items-center">
            <span class="line-clamp-2 text-sm">
              {{ option.text }}
            </span>
            <span class="font-bold"
              >{{
                Math.floor((option.responses_count / totalCount) * 100) || 0
              }}%</span
            >
          </p>
          <div
            :style="{
              width: (option.responses_count / totalCount) * 100 + '%',
              background: pastelColors[index % pastelColors.length],
            }"
            class="h-full absolute left-0 top-0 z-10 rounded-md"
          ></div>
        </li>
      </ul>
      <div class="max-w-60 hidden md:block mt-[-8px] relative">
        <Doughnut
          :data="data"
          :options="chartOptions"
          ref="doughnutChartRef"
          class="z-20 relative"
        />
        <div
          v-if="totalCount"
          class="absolute inset-0 flex items-center justify-center flex-col z-10"
        >
          <p class="text-xs">Total Responses</p>
          <p class="text-5xl font-bold">{{ totalCount }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
