<script setup>
import SurveyQuestionCardOption from '@/components/ui/SurveyQuestionCardOption.vue';
import { defineProps, onMounted, ref } from 'vue';
import PencilIcon from '../icons/PencilIcon.vue';
import FloppyDiskIcon from '@/components/icons/FloppyDiskIcon.vue';
import TrashIcon from '../icons/TrashIcon.vue';
import PlusIcon from '../icons/PlusIcon.vue';
import CircleXMarkIcon from '../icons/CircleXMarkIcon.vue';
import { useStore } from 'vuex';
import axios from 'axios';
import LinkIcon from '../icons/LinkIcon.vue';

const props = defineProps({
  question: Object,
});

const store = useStore();

const isEditing = ref(false);
const isSubmitting = ref(false);
const resetTrigger = ref(false);

const form = ref({
  title: props.question.title,
  link: props.question.link,
  answer_options: [],
});

const resetForm = () => {
  form.value.title = props.question.title;
  form.value.link = props.question.link;
  isEditing.value = false;
  resetTrigger.value = !resetTrigger.value;
  form.value.answer_options = props.question.answer_options.map((option) => ({
    id: option.id,
    text: option.text,
  }));
  console.log(form.value);
};

const updateOptionText = (updatedOption) => {
  const index = form.value.answer_options.findIndex(
    (opt) => opt.id == updatedOption.id
  );
  form.value.answer_options[index].text = updatedOption.text;
};

const deleteOption = (id) => {
  const index = form.value.answer_options.findIndex((opt) => opt.id == id);

  form.value.answer_options.splice(index, 1);
};

const createOption = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  const payload = {
    question_id: props.question.id,
  };
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };

  try {
    let response = await axios.post('answer-options', payload, headers);
    const updatedSurvey = response.data;

    const updatedQuestion = updatedSurvey.questions.find(
      (q) => q.id === props.question.id
    );

    if (updatedQuestion) {
      updatedQuestion.answer_options.forEach((opt) => {
        const exists = form.value.answer_options.some(
          (existingOpt) => existingOpt.id === opt.id
        );
        if (!exists) {
          form.value.answer_options.push({
            id: opt.id,
            text: opt.text,
          });
        }
      });
    }

    store.commit('SET_SURVEY', response.data);
    store.commit('UPDATE_SURVEY', response.data);
  } catch (error) {
    console.error('Error creating option: ', error);
  } finally {
    isSubmitting.value = false;
  }
};

const createLink = () => {
  form.value.link = 'https://example.com/';
};

const handleSubmit = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };

  if (!form.value.link || form.value.link.trim() == '') {
    form.value.link = null;
  }

  try {
    let response = await axios.put(
      `questions/${props.question.id}`,
      form.value,
      headers
    );
    store.commit('SET_SURVEY', response.data);
    store.commit('UPDATE_SURVEY', response.data);
    isEditing.value = false;
  } catch (error) {
    console.error('Error creating question: ', error);
  } finally {
    isSubmitting.value = false;
  }
};

const deleteQuestion = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };

  try {
    let response = await axios.delete(
      `questions/${props.question.id}`,
      headers
    );
    store.commit('SET_SURVEY', response.data);
    store.commit('UPDATE_SURVEY', response.data);
    isEditing.value = false;
  } catch (error) {
    console.error('Error deleting question: ', error);
  } finally {
    isSubmitting.value = false;
  }
};

const type = () => {
  if (props.question.type == 'single_selection') {
    return 'Single Selection';
  } else if (props.question.type == 'open_response') {
    return 'Open Response';
  } else if (props.question.type == 'ranked_voting') {
    return 'Ranked Voting';
  } else {
    return '';
  }
};

onMounted(() => {
  form.value.answer_options = props.question.answer_options.map((option) => ({
    id: option.id,
    text: option.text,
  }));
});
</script>

<template>
  <form @submit.prevent="handleSubmit" class="rounded-lg mb-8 group">
    <div
      class="flex justify-between items-start px-4 py-2 gap-4 bg-gray-100 rounded-lg font-[500]"
    >
      <div class="w-full">
        <p v-if="type()" class="text-sd-gray font-[300] text-xs">
          {{ type() }}
        </p>
        <p v-if="!isEditing" class="mb-1">
          {{ props.question.title }}
        </p>
        <input
          v-if="isEditing"
          type="text"
          v-model="form.title"
          class="w-full bg-transparent focus-visible:outline-none mb-1"
        />
        <div
          v-if="form.link != null"
          class="flex flex-row items-center gap-1 text-xs text-gray-500"
        >
          <LinkIcon class="w-4" v-if="!isEditing" />
          <CircleXMarkIcon
            class="w-4 cursor-pointer"
            v-if="isEditing"
            @click="form.link = null"
          />
          <p v-if="!isEditing">
            {{ props.question.link }}
          </p>
          <input
            v-if="isEditing"
            type="text"
            v-model="form.link"
            class="w-full block bg-transparent focus-visible:outline-none"
          />
        </div>
      </div>
      <div class="flex flex-row items-center gap-4 text-sm mt-1.5">
        <button
          @click="isEditing = true"
          v-if="!isEditing"
          type="button"
          class="text-sm opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300"
        >
          <PencilIcon />
        </button>
        <button v-if="isEditing" class="text-md">
          <FloppyDiskIcon />
        </button>
        <button
          v-if="isEditing && !form.link"
          type="button"
          @click="createLink"
        >
          <LinkIcon />
        </button>
        <button
          v-if="isEditing && props.question.type != 'open_response'"
          type="button"
          @click="createOption"
        >
          <PlusIcon />
        </button>
        <button v-if="isEditing" type="button" @click="deleteQuestion">
          <TrashIcon />
        </button>
        <button v-if="isEditing" type="button" @click="resetForm">
          <CircleXMarkIcon />
        </button>
      </div>
    </div>
    <SurveyQuestionCardOption
      v-for="option in question.answer_options"
      :key="option.id"
      :type="question.type"
      :option="option"
      :isEditing="isEditing"
      :resetTrigger="resetTrigger"
      @update:option="updateOptionText($event, index)"
      @delete:option="deleteOption($event)"
    />
  </form>
</template>
