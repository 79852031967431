<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  holdTime: { type: Number, default: 3000 }, // Time in milliseconds
});

const emit = defineEmits(['trigger']);

const holdProgress = ref(0);
const isHolding = ref(false);
const text = ref('');
let holdTimer = null;

const startHold = () => {
  holdProgress.value = 0;
  isHolding.value = true;
  holdTimer = setInterval(() => {
    if (holdProgress.value < 100) {
      holdProgress.value += (100 * 100) / props.holdTime; // Update progress based on holdTime
      if (holdProgress.value < 33.3) {
        text.value = '3';
      } else if (holdProgress.value >= 33.3 && holdProgress.value < 66.6) {
        text.value = '2';
      } else if (holdProgress.value >= 66.6) {
        text.value = 1;
      }
    } else {
      emit('trigger');
      stopHold();
    }
  }, 100);
};

const stopHold = () => {
  clearInterval(holdTimer);
  text.value = '';
  holdProgress.value = 0;
  isHolding.value = false;
};

const buttonStyle = computed(() => ({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: isHolding.value ? '#ffffff' : '#7f1d1d', // Example colors
  borderColor: isHolding.value ? '#7f1d1d' : '#e5e7eb',
}));
</script>

<template>
  <button
    @mousedown="startHold"
    @mouseup="stopHold"
    @mouseleave="stopHold"
    @touchstart="startHold"
    @touchend="stopHold"
    :class="{ active: isHolding }"
    class="bg-red-900 text-white text-xs px-4 py-2 focus-visible:outline-none border border-solid h-[34px] border-gray-200 shadow-sm rounded-lg inline-flex w-[70px] gap-2 items-center justify-center hover:brightness-125 cursor-pointer"
    :style="buttonStyle"
  >
    <p v-if="!isHolding">Delete</p>
    <div
      class="progress bg-red-900"
      :style="{ width: `${holdProgress}%` }"
    ></div>
    <span
      v-if="isHolding"
      class="absolute rounded-full w-4 h-4 bg-white text-red-900"
      >{{ text }}</span
    >
  </button>
</template>

<style scoped>
.press-and-hold-button {
  padding: 10px 20px;
  color: white;
  border: none;
  cursor: pointer;
  user-select: none;
}

.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: width 0.1s linear;
}
</style>
