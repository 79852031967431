<script setup>
import { computed, defineProps } from 'vue';

// Assuming you are passing `survey` and `progressPercentage` as props to this component
const props = defineProps({
  survey: Object,
  progressPercentage: Number,
});

const logoPath = computed(() => {
  if (props.survey && props.survey.logo) {
    return process.env.VUE_APP_IMAGE_URL + props.survey.logo.path;
  }
  return '';
});
</script>

<template>
  <div class="fixed top-0 left-0 w-full z-20">
    <div
      class="flex flex-row gap-4 justify-between items-center px-16 py-2 bg-white"
    >
      <router-link
        :to="{ name: 'dashboard' }"
        class="h-8 w-full object-contain sm:h-12 sm:w-auto"
      >
        <img
          src="/Stephens_Logo_Hor.png"
          class="h-8 max-w-full object-contain sm:h-12 sm:w-auto"
        />
      </router-link>
      <img
        v-if="logoPath"
        :src="logoPath"
        class="h-8 max-w-full object-contain sm:h-12 sm:w-auto"
      />
    </div>
    <div
      :style="{
        width: progressPercentage + '%',
        transition: 'width 0.5s ease-out',
      }"
      class="bg-sd-blue-light h-1.5 mr-auto"
    ></div>
  </div>
</template>
