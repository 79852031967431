<script setup>
import { defineProps, computed } from 'vue';

const props = defineProps({
  question: Object,
});

const rankedResponses = computed(() => {
  // Determine the maximum rank dynamically from the responses
  const maxRank = Math.max(
    ...props.question.answer_options.flatMap((option) =>
      option.responses.map((response) => response.rank || 0)
    )
  );

  const responsesData = props.question.answer_options.map((option) => {
    const rankTotals = {};
    let totalRanks = 0;
    let sumRanks = 0;

    // Initialize all ranks from 1 to maxRank to 0
    for (let rank = 1; rank <= maxRank; rank++) {
      rankTotals[rank] = 0;
    }

    option.responses.forEach((response) => {
      const rank = response.rank || 0;
      if (rank > 0) {
        // Only consider valid ranks
        rankTotals[rank]++;
        totalRanks++;
        sumRanks += rank;
      }
    });

    const averageRank = totalRanks > 0 ? sumRanks / totalRanks : 0;

    return {
      title: option.text,
      rankTotals,
      averageRank: averageRank.toFixed(2),
    };
  });

  // Sort the responses by average rank, ascending (lowest average rank at the top)
  const sortedResponses = responsesData.sort(
    (a, b) => parseFloat(a.averageRank) - parseFloat(b.averageRank)
  );

  // Add display rank (1st, 2nd, 3rd, etc.)
  sortedResponses.forEach((response, index) => {
    response.displayRank = `${index + 1}${getOrdinalSuffix(index + 1)}`;
  });

  return sortedResponses;
});

const getOrdinalSuffix = (i) => {
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
};
</script>

<template>
  <div>
    <h2 class="text-lg mb-4 font-[500]">{{ question.title }}</h2>
    <div class="flex flex-row gap-16 items-start mb-4">
      <ul class="flex-1">
        <li
          v-for="option in rankedResponses"
          :key="option.title"
          class="w-full bg-Paper mb-4 last:mb-0 py-2 rounded-md px-4 relative transition-all duration-200 hover:scale-[1.02] hover:shadow-md"
        >
          <p
            class="z-20 relative flex flex-row justify-between items-center mb-2"
          >
            <span class="line-clamp-2 text-sm font-bold">
              {{ option.title }}
            </span>
            <span class="text-sm font-bold">
              {{ option.displayRank }}
            </span>
          </p>
          <hr />
          <div
            class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mt-4"
          >
            <div v-for="(rank, index) in option.rankTotals" :key="index">
              <p class="font-bold text-2xl">{{ rank }}</p>
              <p class="text-sm">Rank {{ index }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
