<script setup>
import SubmitButton from '@/components/ui/SubmitButton.vue';
import SurveyQuestion from '@/components/ui/SurveyQuestion.vue';
import SurveyHeader from '@/components/ui/SurveySlugHeader.vue';
import axios from 'axios';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const survey = ref({});
const showPassword = ref(false);
const showThankYou = ref(false);
const showPasswordError = ref(false);
const showQuestions = ref(false);
const isHovering = ref(false);
const currentQuestionIndex = ref(0);
const form = ref({
  password: '',
});
const showNameInput = ref(false);
const name = ref('');

const progressPercentage = computed(() => {
  if (survey.value.questions) {
    return (currentQuestionIndex.value / survey.value.questions.length) * 100;
  } else {
    return 0;
  }
});

const nextQuestion = () => {
  if (currentQuestionIndex.value < survey.value.questions.length - 1) {
    currentQuestionIndex.value++;
  } else {
    currentQuestionIndex.value = survey.value.questions.length;
    showQuestions.value = false;
    showThankYou.value = true;
  }
};

const startSurvey = () => {
  if (!survey.value.anon) {
    showNameInput.value = true;
  } else {
    showQuestions.value = true;
  }
};

const handleNameSubmit = () => {
  if (name.value.trim()) {
    showNameInput.value = false;
    showQuestions.value = true;
  }
};

const handlePassword = async () => {
  try {
    const response = await axios.post(
      `surveys/slug/${route.params.slug}`,
      form.value,
      {}
    );
    if (response.data.is_password_protected) {
      showPasswordError.value = true;
    } else {
      showPasswordError.value = false;
      showPassword.value = false;
      survey.value = response.data;
    }
  } catch (error) {
    console.error('Error submitting survey password: ', error);
  }
};

onMounted(async () => {
  try {
    const response = await axios.get(`surveys/slug/${route.params.slug}`);
    if (response.data.is_password_protected) {
      showPassword.value = true;
    } else {
      survey.value = response.data;
    }
  } catch (error) {
    console.error('Error finding survey: ', error);
    if (error.response && error.response.status === 404) {
      router.push({ path: '/404' });
    }
  }
});

// const buttonGradient = computed(() => {
//   if (!survey.value?.styles) return {};
//   return {
//     backgroundImage: `linear-gradient(to right, ${survey.value?.styles?.background_color} 50%, ${survey.value?.styles?.gradient_start}  50%, ${survey.value?.styles?.gradient_stop})`,
//     color: isHovering.value
//       ? survey.value?.styles?.hover_text_color
//       : survey.value?.styles?.text_color,
//   };
// });
</script>

<template>
  <section class="relative bg-sd-bg overflow-hidden">
    <SurveyHeader :survey="survey" :progressPercentage="progressPercentage" />
    <div
      class="min-h-dvh w-dvh flex items-center justify-center max-w-4xl mx-auto py-8 px-4"
    >
      <form
        v-if="showPassword"
        @submit.prevent="handlePassword"
        class="bg-white rounded-3xl m-4 p-10 md:p-20 max-w-xl w-full flex flex-col gap-8 z-10"
      >
        <img
          src="@/assets/Stephens_Logo_Stacked.png"
          alt="Stephens Direct"
          class="w-full max-w-80 mx-auto"
        />
        <input
          type="password"
          v-model="form.password"
          placeholder="Password"
          class="px-4 py-3 rounded-md border border-black bg-paper text-lg focus:outline-0 focus:border-gray-500 w-full shadow-md"
        />
        <div class="relative mb-4 md:mb-0">
          <SubmitButton class="mx-auto block h-[44px]"> Submit </SubmitButton>
          <div
            v-if="showPasswordError"
            class="text-red-700 text-xs mx-auto mt-4 text-center absolute left-[50%] translate-x-[-50%]"
          >
            Incorrect Password
          </div>
        </div>
      </form>
      <div
        v-if="
          !showPassword && !showQuestions && !showThankYou && !showNameInput
        "
        class="min-h-[484px] bg-white rounded-3xl m-4 p-4 md:p-20 max-w-3xl w-full flex justify-center flex-col gap-8 z-10"
      >
        <div class="text-sd-gray">
          <h1 class="text-2xl font-bold text-center mb-2">
            {{ survey?.title }}
          </h1>
          <p
            v-if="survey?.questions?.length"
            class="text-center text-2xl font-light"
          >
            {{ survey?.questions?.length }} question<span
              v-if="survey?.questions?.length > 1"
              >s</span
            >
          </p>
        </div>
        <SubmitButton
          @click="startSurvey"
          @mouseover="isHovering = true"
          @mouseout="isHovering = false"
          class="w-fit mx-auto"
        >
          Start
        </SubmitButton>
      </div>
      <form
        @submit.prevent="handleNameSubmit"
        v-if="showNameInput"
        class="min-h-[484px] bg-white rounded-3xl m-4 p-4 md:p-20 max-w-3xl w-full flex justify-center flex-col gap-8 z-10"
      >
        <h1 class="text-2xl font-bold text-center mb-2">
          Please enter your full name.
        </h1>
        <input
          type="text"
          v-model="name"
          placeholder="Enter your name"
          class="px-4 py-3 rounded-md border border-black bg-paper text-lg focus:outline-0 focus:border-gray-500 w-full shadow-md"
        />
        <SubmitButton
          class="mx-auto block h-[44px]"
          :isDisabled="name.trim() ? false : true"
        >
          Continue
        </SubmitButton>
      </form>
      <SurveyQuestion
        v-if="
          survey?.questions && survey?.questions.length > 0 && showQuestions
        "
        :key="currentQuestionIndex"
        :question="survey.questions[currentQuestionIndex]"
        :total="survey?.questions.length"
        :current="currentQuestionIndex + 1"
        :styles="survey?.styles"
        :name="name"
        @answer="nextQuestion"
        class="z-10 m-4"
      />
      <div
        v-if="showThankYou"
        class="min-h-[484px] bg-white rounded-3xl m-4 p-4 md:p-20 max-w-3xl w-full flex justify-center flex-col gap-8 z-10"
      >
        <h1
          class="text-2xl sm:text-4xl font-bold text-center bg-gradient-to-r text-transparent bg-clip-text"
          style="
            background-image: linear-gradient(
              90deg,
              #ab017b 13.92%,
              #b1007f 19%,
              #c3008b 24.93%,
              #e000a0 32.56%,
              #ff00b5 39.33%,
              #f700b4 41.03%,
              #e401b1 43.57%,
              #c403ae 46.96%,
              #9705a9 51.19%,
              #5e08a2 55.43%,
              #190b9a 59.66%,
              #050d98 60.51%,
              #00cbc3 81.69%,
              #00c2db 97.78%
            );
          "
        >
          <span class="text-5xl sm:text-[4.75rem]" style="line-height: 1"
            >THANK YOU</span
          ><br />for completing the survey!
        </h1>
        <p class="text-center text-sd-gray text-base sm:text-2xl font-light">
          You may now close the window.
        </p>
      </div>
    </div>

    <img
      src="@/assets/wave.svg"
      class="fixed max-w-[300%] z-0 top-[70vh] min-h-[30vh]"
    />
    <img
      src="@/assets/wave.svg"
      class="fixed max-w-[300%] z-0 bottom-[70vh] min-h-[30vh] rotate-180"
    />
  </section>
</template>
