<template>
  <router-view />
</template>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600&display=swap');

@font-face {
  font-family: 'Mona Sans';
  src: url('/public/fonts/Mona-Sans.woff2') format('woff2 supports variations'),
    url('/public/fonts/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}

body {
  overflow-x: hidden;
}
</style>
