import LogInView from '@/components/views/auth/LogInView.vue';
import NotFoundView from '@/components/views/NotFoundView.vue';
import DashboardView from '@/components/views/admin/DashboardView.vue';
import AuthLayout from '@/layouts/AuthLayout.vue';
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import SurveyDetailView from '@/components/views/admin/survey/SurveyDetailView.vue';
import SurveyFormView from '@/components/views/admin/survey/SurveyFormView.vue';
import SurveySlugView from '@/components/views/admin/survey/SurveySlugView.vue';
import SurveyResponsesView from '@/components/views/admin/survey/SurveyResponsesView.vue';
import UserProfileView from '@/components/views/admin/user/UserProfileView.vue';
import RedirectView from '@/components/views/RedirectView.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: LogInView,
  },
  {
    path: '/survey/:id/responses',
    name: 'survey-responses',
    component: SurveyResponsesView,
  },
  {
    path: '/dashboard',
    component: AuthLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'survey/:id',
        name: 'survey-detail',
        component: SurveyDetailView,
      },
      {
        path: 'survey/:id/edit',
        name: 'survey-edit',
        component: SurveyFormView,
      },
      {
        path: 'survey/add',
        name: 'survey-add',
        component: SurveyFormView,
      },
      {
        path: '/profile',
        name: 'user-profile',
        component: UserProfileView,
      },
    ],
  },
  { path: '/app/:slug', name: 'survey-slug', component: SurveySlugView },
  {
    path: '/app/redirect/:slug',
    name: 'survey-redirect',
    component: RedirectView,
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundView },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isLoggedIn = store.getters.isLoggedIn;

  if (requiresAuth && !isLoggedIn) {
    next('/login');
  } else if (to.path === '/login' && isLoggedIn) {
    next('/dashboard');
  } else {
    next();
  }
});

export default router;
