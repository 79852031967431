<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import SubmitButton from '@/components/ui/SubmitButton.vue';

import EyePositiveIcon from '@/components/icons/EyePositiveIcon.vue';
import EyeNegativeIcon from '@/components/icons/EyeNegativeIcon.vue';
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue';

const store = useStore();
const router = useRouter();

const loginForm = ref({
  email: '',
  password: '',
});

const showPassword = ref(false);
const isLoggingIn = ref(false);
const loginError = ref('');

const isLoginDisabled = computed(() => {
  let email = loginForm.value.email.trim();
  let password = loginForm.value.password;
  return email === '' || password === '';
});

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const performLogin = async () => {
  if (isLoggingIn.value) {
    return;
  }

  isLoggingIn.value = true;
  loginError.value = '';

  try {
    await store.dispatch('login', loginForm.value);
    router.push({ name: 'dashboard' });
  } catch (error) {
    loginError.value =
      error.response?.data?.message || 'An unknown error occured';
  } finally {
    isLoggingIn.value = false;
  }
};
</script>

<template>
  <div
    class="flex w-screen min-h-screen items-center justify-center relative bg-sd-bg overflow-hidden"
  >
    <div
      class="bg-white rounded-3xl m-4 p-10 md:p-20 max-w-xl w-full flex flex-col z-10"
    >
      <div>
        <div class="flex flex-col gap-3 mb-8">
          <img
            src="@/assets/Stephens_Logo_Stacked.png"
            alt="Stephens Direct"
            class="w-full max-w-80 mx-auto"
          />
        </div>
        <form @submit.prevent="performLogin" class="flex flex-col gap-8">
          <div class="">
            <label for="email" class="hidden">Email</label>
            <input
              id="email"
              type="email"
              v-model="loginForm.email"
              placeholder="user@email.com"
              class="px-4 py-3 rounded-md border border-black bg-paper text-lg focus:outline-0 focus:border-gray-500 w-full shadow-md"
            />
          </div>
          <div class="relative">
            <label for="password" class="hidden">Password</label>
            <input
              id="password"
              :type="showPassword ? 'text' : 'password'"
              v-model="loginForm.password"
              placeholder="············"
              class="px-4 py-3 rounded-md border border-black bg-paper text-lg focus:outline-0 focus:border-gray-500 w-full shadow-md"
            />
            <div
              @click="toggleShowPassword"
              class="cursor-pointer absolute z-10 bottom-4 right-2"
            >
              <EyePositiveIcon v-if="!showPassword" class="w-8 text-xl" />
              <EyeNegativeIcon v-if="showPassword" class="w-8 text-xl" />
            </div>
          </div>
          <div class="relative mb-4 md:mb-0">
            <SubmitButton
              :is-disabled="isLoggingIn || isLoginDisabled"
              class="mx-auto block h-[44px]"
            >
              <span v-if="!isLoggingIn">Login</span>
              <SpinnerIcon
                v-if="isLoggingIn"
                class="animate-spin h-[1.125rem] w-12"
              />
            </SubmitButton>
            <div
              v-if="loginError"
              class="text-red-700 text-xs mx-auto mt-4 text-center absolute left-[50%] translate-x-[-50%]"
            >
              {{ loginError }}
            </div>
          </div>
        </form>
      </div>
    </div>
    <img
      src="@/assets/wave.svg"
      class="absolute max-w-[300%] z-0 top-[70vh] min-h-[30vh]"
    />
    <img
      src="@/assets/wave.svg"
      class="absolute max-w-[300%] z-0 bottom-[70vh] min-h-[30vh] rotate-180"
    />
  </div>
</template>
