<script setup>
import { defineProps, defineEmits } from 'vue';
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import CircleXMarkIcon from '../icons/CircleXMarkIcon.vue';

const props = defineProps({
  qrCodeData: String,
});

const emits = defineEmits(['close', 'download']);
</script>

<template>
  <div
    class="flex h-screen w-full absolute inset-0 items-center justify-center"
  >
    <div class="z-30 bg-white py-16 px-8 rounded-md">
      <img :src="props.qrCodeData" alt="QR Code" class="mb-8" />
      <div class="flex flex-row gap-2 items-center w-fit mx-auto">
        <button
          class="bg-white text-sm px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95 font-[500]"
          @click="emits('download')"
        >
          <DownloadIcon />
        </button>
        <button
          class="bg-gray-200 text-sm px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center hover:brightness-95 font-[500]"
          @click="emits('close')"
        >
          <CircleXMarkIcon />
        </button>
      </div>
    </div>
    <div
      class="absolute inset-0 bg-black opacity-75 z-20"
      @click="emits('close')"
    ></div>
  </div>
</template>
