<script setup>
import { defineProps, computed, ref } from 'vue';

const props = defineProps({
  question: Object,
});

const seeMore = ref(false);

const responses = computed(() => {
  let r = props.question.answer_options[0].responses.slice().reverse();
  if (!seeMore.value) {
    return r.slice(0, 3);
  }
  return r;
});

const toggleSeeMore = () => {
  seeMore.value = !seeMore.value;
};
</script>

<template>
  <div>
    <h2 class="text-lg mb-4 font-[500]">{{ question.title }}</h2>
    <div class="flex flex-row gap-16 items-start mb-4">
      <ul class="flex-1">
        <li
          v-for="response in responses"
          :key="response.id"
          class="w-full bg-Paper mb-4 last:mb-0 py-2 rounded-md px-4 relative transition-all duration-200 hover:scale-[1.02] hover:shadow-md"
        >
          <p class="z-20 relative flex flex-row justify-between items-center">
            <span class="line-clamp-2 text-sm">
              {{ response.text_response }}
            </span>
          </p>
        </li>
      </ul>
    </div>
    <button
      @click="toggleSeeMore"
      class="bg-sd-blue-dark text-white hover:brightness-125 text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center font-[500]"
    >
      See {{ seeMore ? 'Less' : 'More' }}
    </button>
  </div>
</template>
