<script setup>
import axios from 'axios';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const user = computed(() => store.getters.currentUser);
const isSubmitting = ref(false);
const token = computed(() => store.state.token);
const router = useRouter();

const showSuccessMessage = ref(false);
const errorMessage = ref('');

const form = ref({
  password: '',
  new_password: '',
  new_password_confirmation: '',
});

const isValidNewPassword = computed(() => {
  if (!form.value.new_password.length) {
    return true;
  } else {
    return form.value.new_password.length >= 8;
  }
});

const isPasswordConfirmed = computed(() => {
  if (!form.value.new_password_confirmation.length) {
    return true;
  } else {
    return form.value.new_password === form.value.new_password_confirmation;
  }
});

const handlePasswordChange = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  showSuccessMessage.value = false;
  errorMessage.value = '';

  const headers = {
    headers: {
      Authorization: `Bearer ${token.value}`,
    },
  };

  try {
    await axios.post('/change-password', form.value, headers);
    showSuccessMessage.value = true;
    form.value.new_password = '';
    form.value.new_password_confirmation = '';
    form.value.password = '';
  } catch (error) {
    console.error('Error submitting form: ', error);
    errorMessage.value = 'Error changing password, try again later.';
  } finally {
    isSubmitting.value = false;
  }
};

const handleLogout = async () => {
  try {
    const response = await axios.post(
      '/logout',
      {},
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    console.log(response.data.message); // Success message
    store.dispatch('logout'); // Assuming you have a Vuex action to clear user state
    router.push('/login'); // Redirect to login or home page
  } catch (error) {
    console.error('Logout Error: ', error.response.data.message);
  }
};
</script>

<template>
  <section class="p-8">
    <div class="max-w-7xl mx-auto px-2">
      <div class="mb-8 flex justify-between flex-col sm:flex-row sm:items-end">
        <div class="mb-2 sm:mb-0">
          <p class="pl-0.5 text-gray-500 text-sm">Profile</p>
          <h2 class="text-2xl pl-0.5 font-[500]">{{ user?.name }}</h2>
        </div>
        <button
          @click="handleLogout"
          class="bg-red-900 text-white text-xs h-fit px-4 py-2 focus-visible:outline-none w-fit border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center hover:brightness-125 cursor-pointer"
        >
          Logout
        </button>
      </div>
      <div class="bg-gray-50 border border-gray-100 shadow-sm px-8 py-8">
        <div class="mb-8 flex flex-row justify-between">
          <p class="text-sm font-[500] mb-1">Change Password</p>
          <p class="text-xs">
            <span class="text-red-600 mr-0.5">*</span>Required
          </p>
        </div>
        <form
          @submit.prevent="handlePasswordChange"
          class="grid grid-cols-1 md:grid-cols-2 gap-8"
        >
          <div class="md:col-span-2">
            <label
              for="currentPassword"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Current Password<span class="text-red-600 ml-0.5">*</span></label
            >
            <input
              id="currentPassword"
              type="password"
              required
              v-model="form.password"
              class="w-full text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
            />
          </div>
          <div>
            <label
              for="newPassword"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >New Password<span class="text-red-600">*</span></label
            >
            <input
              id="newPassword"
              type="password"
              required
              v-model="form.new_password"
              class="w-full text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
            />
            <p v-if="!isValidNewPassword" class="text-red-500 text-xs mt-1">
              Password must be at least 8 characters long.
            </p>
          </div>
          <div>
            <label
              for="newPasswordConfirmation"
              class="block text-sm mb-2 pl-0.5 text-gray-500"
              >Confirm New Password<span class="text-red-600">*</span></label
            >
            <input
              id="newPasswordConfirmation"
              type="password"
              required
              v-model="form.new_password_confirmation"
              class="w-full text-sm px-3 py-2 rounded-lg focus-visible:outline-none text-gray-500 bg-gray-100 border border-solid border-gray-200 shadow-inner"
            />
            <p v-if="!isPasswordConfirmed" class="text-red-500 text-xs mt-1">
              Passwords do not match.
            </p>
          </div>
          <div>
            <p v-if="showSuccessMessage" class="text-xs text-gray-500">
              Password Changed Successfully!
            </p>
            <p v-if="errorMessage" class="text-xs text-red-900">
              {{ errorMessage }}
            </p>
            <button
              class="bg-sd-blue-dark mt-2 text-white text-xs px-4 py-2 focus-visible:outline-none w-fit border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center hover:brightness-125 cursor-pointer"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
