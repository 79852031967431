<script setup>
import { defineProps, ref, watchEffect, defineEmits } from 'vue';
import TrashIcon from '../icons/TrashIcon.vue';
import { useStore } from 'vuex';
import axios from 'axios';

const props = defineProps({
  option: Object,
  isEditing: Boolean,
  resetTrigger: Boolean,
  type: String,
});

const store = useStore();

const emit = defineEmits(['update:option', 'delete:option']);

const editableText = ref(props.option.text);
const isSubmitting = ref(false);

const handleInput = () => {
  emit('update:option', { ...props.option, text: editableText.value });
};

const deleteOption = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };

  let id = props.option.id;

  try {
    let response = await axios.delete(`answer-options/${id}`, headers);
    emit('delete:option', id);
    store.commit('SET_SURVEY', response.data);
    store.commit('UPDATE_SURVEY', response.data);
  } catch (error) {
    console.error('Error deleting option: ', error);
  } finally {
    isSubmitting.value = false;
  }
};

watchEffect(() => {
  if (props.resetTrigger) {
    editableText.value = props.option.text;
  }
});
</script>

<template>
  <div
    class="flex flex-row gap-4 items-center px-4 py-2 border-b border-gray-300 last:border-b-0 min-h-[49px]"
  >
    <div class="w-3 h-3 rounded-full border-2 border-gray-400"></div>
    <span
      v-if="!isEditing || props.type == 'open_response'"
      class="text-sm flex-1"
      >{{ props.option.text }}</span
    >
    <span
      v-if="!isEditing || props.type == 'open_response'"
      class="text-md font-bold"
      >{{ props.option.responses_count }}</span
    >
    <input
      v-if="isEditing && props.type != 'open_response'"
      type="text"
      v-model="editableText"
      @input="handleInput"
      class="text-sm w-full bg-transparent focus-visible:outline-none"
    />
    <button
      v-if="isEditing && props.type != 'open_response'"
      type="button"
      @click="deleteOption"
      class="text-sm"
    >
      <TrashIcon />
    </button>
  </div>
</template>
