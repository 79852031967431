import { createStore } from 'vuex';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default createStore({
  state: {
    user: null,
    token: localStorage.getItem('token') || null,
    surveys: [],
    currentSurvey: null,
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    currentUser: (state) => state.user,
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    LOGOUT(state) {
      state.token = null;
      state.user = null;
    },
    SET_SURVEYS(state, surveys) {
      state.surveys = surveys;
    },
    SET_SURVEY(state, survey) {
      state.currentSurvey = survey;
    },
    ADD_SURVEY(state, survey) {
      state.surveys.push(survey);
    },
    UPDATE_SURVEY(state, survey) {
      const surveyIndex = state.surveys.findIndex((sur) => survey.id == sur.id);
      if (surveyIndex != -1) {
        state.surveys[surveyIndex] = survey;
      } else {
        state.surveys.push(survey);
      }
    },
    DELETE_SURVEY(state, survey) {
      const surveyIndex = state.surveys.findIndex((sur) => survey.id == sur.id);
      if (surveyIndex != -1) {
        state.surveys.splice(surveyIndex, 1);
      }
    },
  },
  actions: {
    login({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', credentials)
          .then((response) => {
            const token = response.data.token;
            const user = response.data.user;
            localStorage.setItem('token', token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            commit('SET_TOKEN', token);
            commit('SET_USER', user);
            resolve(response);
          })
          .catch((error) => {
            localStorage.removeItem('token');
            reject(error);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('LOGOUT');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    initializeAuth({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.token) {
          axios.defaults.headers.common['Authorization'] =
            'Bearer ' + state.token;
          axios
            .get('/user')
            .then((response) => {
              commit('SET_USER', response.data);
              resolve(response);
            })
            .catch((error) => {
              commit('LOGOUT');
              reject(error);
            });
        } else {
          reject('No token found');
        }
      });
    },
    async fetchSurveys({ commit }) {
      try {
        const response = await axios.get('/surveys');
        commit('SET_SURVEYS', response.data);
      } catch (error) {
        console.error('Error fetching surveys: ', error);
      }
    },
    async fetchSurvey({ commit }, id) {
      try {
        const response = await axios.get(`/surveys/${id}`);
        commit('SET_SURVEY', response.data);
      } catch (error) {
        console.error('Error fetching survey: ', error);
      }
    },
  },
});
