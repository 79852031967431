<script setup>
import PencilIcon from '@/components/icons/PencilIcon.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import ChartIcon from '@/components/icons/ChartIcon.vue';
import EyeNegativeIcon from '@/components/icons/EyeNegativeIcon.vue';
import EyePositiveIcon from '@/components/icons/EyePositiveIcon.vue';
import QRIcon from '@/components/icons/QRIcon.vue';
import CloneIcon from '@/components/icons/CloneIcon.vue';
import SurveyQuestionCard from '@/components/ui/SurveyQuestionCard.vue';
import QrCodeLightbox from '@/components/ui/QrCodeLightbox.vue';
import axios from 'axios';
import { format, isPast, isFuture } from 'date-fns';
import { computed, onMounted, ref, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import AngleIcon from '@/components/icons/AngleIcon.vue';
import router from '@/router';

const store = useStore();
const route = useRoute();

const survey = computed(() => store.state.currentSurvey);

const isSubmitting = ref(false);
const displayActivationModal = ref(false);
const expirationDate = ref('');
const expirationError = ref('');
const showQrCodeLightbox = ref(false);
const qrCodeData = computed(() => survey.value.qr_code.code);
const moreOptions = ref(false);
const moreOptionsContainer = ref(null);
const showQuestionTypes = ref(false);

const toggleMoreOptions = () => {
  moreOptions.value = !moreOptions.value;
};

const handleClickOutside = (event) => {
  if (!moreOptionsContainer.value.contains(event.target)) {
    moreOptions.value = false;
  }
};

const handleQrCodeClick = () => {
  showQrCodeLightbox.value = true;
};

const downloadQrCode = () => {
  const link = document.createElement('a');
  link.href = qrCodeData.value;
  link.download = `${survey.value.title} QR code.png`; // You can dynamically set the file name based on survey data
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const formatDateWithStatus = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = format(date, "EEEE, MMMM do 'at' h:mm b");
  return isPast(date)
    ? `Expired on ${formattedDate}`
    : `Expires on ${formattedDate}`;
};

const handleActivationModal = () => {
  if (survey.value.active) {
    handleActivation();
  } else {
    displayActivationModal.value = true;
  }
};

const handleActivation = async (setExpiration = false) => {
  if (isSubmitting.value) return;

  expirationError.value = '';

  if (setExpiration) {
    if (!expirationDate.value) {
      expirationError.value = 'Please select a date.';
      return;
    }

    const selectedDate = new Date(expirationDate.value);
    if (!isFuture(selectedDate)) {
      expirationError.value = 'The date must be in the future.';
      return;
    }
  }

  isSubmitting.value = true;
  const payload = setExpiration
    ? { expiration_date: expirationDate.value }
    : {};
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };

  try {
    let response = await axios.post(
      `/surveys/activate/${route.params.id}`,
      payload,
      headers
    );
    store.commit('SET_SURVEY', response.data);
    displayActivationModal.value = false;
    expirationDate.value = '';
  } catch (error) {
    console.error('Error toggling survey activation: ', error);
  } finally {
    isSubmitting.value = false;
  }
};

const addQuestion = async (type) => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  const payload = {
    survey_id: route.params.id,
    type,
  };
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };

  try {
    let response = await axios.post('/questions', payload, headers);
    store.commit('SET_SURVEY', response.data);
    store.commit('UPDATE_SURVEY', response.data);
  } catch (error) {
    console.error('Error creating question: ', error);
  } finally {
    isSubmitting.value = false;
    showQuestionTypes.value = false;
  }
};

const handleDuplication = async () => {
  if (isSubmitting.value) return;

  isSubmitting.value = true;
  const headers = {
    headers: {
      Authorization: `Bearer ${store.state.token}`,
    },
  };
  try {
    let response = await axios.post(
      `/surveys/${route.params.id}/duplicate`,
      headers
    );
    router.push({ name: 'survey-edit', params: { id: response.data.id } });
  } catch (error) {
    console.error('Error creating question: ', error);
  } finally {
    isSubmitting.value = false;
  }
};

onMounted(() => {
  store.dispatch('fetchSurvey', route.params.id);
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <section class="p-8">
    <div class="max-w-7xl mx-auto px-2 mb-8">
      <div class="flex flex-col lg:flex-row lg:gap-2 gap-4 items-start">
        <div class="flex-1 pl-0.5 order-2 lg:order-1">
          <h2 class="text-2xl font-[500]">
            {{ survey?.title }}
          </h2>
          <p v-if="survey?.expiration_date" class="text-sm text-gray-500">
            {{ formatDateWithStatus(survey.expiration_date) }}
          </p>
          <p
            v-if="!survey?.expiration_date && survey?.active"
            class="text-sm text-gray-500"
          >
            No Expiration Date
          </p>
        </div>
        <div class="flex flex-row gap-2 items-center order-1 lg:order-2">
          <button
            @click="handleActivationModal"
            :class="
              survey?.active
                ? 'bg-sd-blue-dark text-white hover:brightness-125'
                : 'bg-white text-black hover:brightness-95'
            "
            class="text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex gap-2 items-center cursor-pointer font-[500]"
          >
            {{ survey?.active ? 'Active' : 'Set Active' }}
          </button>
          <div ref="moreOptionsContainer" class="relative">
            <button
              class="bg-white text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg inline-flex justify-center gap-2 items-center hover:brightness-95 font-[500] disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
              @click="toggleMoreOptions"
            >
              More
              <AngleIcon
                :class="moreOptions ? 'rotate-0' : 'rotate-180'"
                class="transition-all duration-200"
              />
            </button>
            <div
              v-show="moreOptions"
              class="absolute w-max rounded-lg bg-white text-xs shadow-sm border border-solid border-gray-200 grid grid-cols-1 overflow-hidden top-[120%] right-0 z-20"
            >
              <RouterLink
                :to="{ name: 'survey-responses', params: { id: survey?.id } }"
                class="bg-white font-[500] px-4 py-2 flex gap-2 items-center justify-start hover:brightness-95 disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
              >
                <ChartIcon class="w-[1em]" />Report
              </RouterLink>
              <RouterLink
                :to="{ name: 'survey-edit', params: { id: survey?.id } }"
                class="bg-white font-[500] px-4 py-2 flex gap-2 items-center justify-start hover:brightness-95 disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
              >
                <PencilIcon class="w-[1em]" /> Edit
              </RouterLink>
              <RouterLink
                v-if="survey?.active"
                :to="{
                  name: 'survey-slug',
                  params: { slug: survey?.slug },
                }"
                class="bg-white font-[500] px-4 py-2 flex gap-2 items-center justify-start hover:brightness-95 disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
              >
                <EyePositiveIcon class="w-[1em]" />
                View
              </RouterLink>
              <button
                v-if="!survey?.active"
                disabled
                class="bg-white font-[500] px-4 py-2 flex gap-2 items-center justify-start hover:brightness-95 disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
              >
                <EyeNegativeIcon class="w-[1em]" />
                View
              </button>
              <button
                class="bg-white font-[500] px-4 py-2 flex gap-2 items-center justify-start hover:brightness-95 disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
                :disabled="!survey?.qr_code || !survey?.active"
                @click="handleQrCodeClick"
              >
                <QRIcon />
                QR Code
              </button>
              <button
                class="bg-white font-[500] px-4 py-2 flex gap-2 items-center justify-start hover:brightness-95 disabled:bg-gray-200 disabled:hover:brightness-100 disabled:cursor-not-allowed"
                @click="handleDuplication"
              >
                <CloneIcon />
                Duplicate
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8">
        <SurveyQuestionCard
          v-for="question in survey?.questions"
          :key="question.id"
          :question="question"
        />
      </div>
      <div class="mt-8">
        <button
          v-if="!showQuestionTypes"
          @click="showQuestionTypes = true"
          class="bg-gray-200 text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center hover:brightness-95 font-[500] mx-auto"
        >
          <PlusIcon class="w-full" /> Add&nbsp;Question
        </button>
        <div
          v-if="showQuestionTypes"
          class="flex gap-4 flex-row items-center justify-center mx-auto w-min"
        >
          <button
            @click="addQuestion('single_selection')"
            class="bg-sd-blue-dark text-white hover:brightness-125 text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center font-[500] mx-auto"
          >
            Single&nbsp;Selection
          </button>
          <button
            v-if="survey?.anon"
            @click="addQuestion('multiple_selection')"
            class="bg-sd-blue-dark text-white hover:brightness-125 text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center font-[500] mx-auto"
          >
            Multiple&nbsp;Selection
          </button>
          <button
            v-if="survey?.anon"
            @click="addQuestion('open_response')"
            class="bg-sd-blue-dark text-white hover:brightness-125 text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center font-[500] mx-auto"
          >
            Open&nbsp;Response
          </button>
          <button
            v-if="survey?.anon"
            @click="addQuestion('ranked_voting')"
            class="bg-sd-blue-dark text-white hover:brightness-125 text-xs px-4 py-2 focus-visible:outline-none border border-solid border-gray-200 shadow-sm rounded-lg flex gap-2 items-center font-[500] mx-auto"
          >
            Ranked&nbsp;Voting
          </button>
        </div>
        <input type="number" />
      </div>
    </div>
    <div
      v-if="displayActivationModal"
      class="z-20 absolute inset-0 p-4 flex items-center justify-center"
    >
      <div class="p-4 bg-slate-50 rounded-sm m-2">
        <p class="text-xl font-bold mb-4">
          Set {{ survey?.active ? 'Inactive' : 'Active' }}
        </p>
        <div>
          <p class="text-lg mb-1">Set Expiration?</p>
          <div class="flex gap-4 mb-2">
            <button
              class="bg-slate-300 mb-0.5 shadow-sm px-3 py-1 rounded-sm font-bold hover:brightness-90 text-sm h-fit transition-all duration-200"
              @click="handleActivation(true)"
            >
              Yes
            </button>
            <button
              class="bg-slate-300 mb-0.5 shadow-sm px-3 py-1 rounded-sm font-bold hover:brightness-90 text-sm h-fit transition-all duration-200"
              @click="handleActivation()"
            >
              No
            </button>
          </div>
          <input type="datetime-local" v-model="expirationDate" />
          <p v-if="expirationError" class="text-red-500 text-sm mt-1">
            {{ expirationError }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="displayActivationModal"
      class="z-10 absolute inset-0 bg-black opacity-50"
    ></div>
    <QrCodeLightbox
      v-if="showQrCodeLightbox"
      :qrCodeData="qrCodeData"
      @download="downloadQrCode"
      @close="showQrCodeLightbox = false"
    />
  </section>
</template>
