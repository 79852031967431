<script setup>
import { defineProps, onMounted, onUnmounted, ref, defineEmits } from 'vue';
import EyePositiveIcon from '../icons/EyePositiveIcon.vue';
import TrashIcon from '../icons/TrashIcon.vue';
import PencilIcon from '../icons/PencilIcon.vue';
import { formatDistanceToNow } from 'date-fns';
import LinkIcon from '../icons/LinkIcon.vue';
import EyeNegativeIcon from '../icons/EyeNegativeIcon.vue';
import EllipsisIcon from '@/components/icons/EllipsisIcon.vue';

const props = defineProps({
  survey: Object,
});

const expirationTime = (date) => {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

const copied = ref(false);

const isActionsBoxVisible = ref(false);
const componentRef = ref(null);
const buttonRef = ref(null);

const emit = defineEmits('delete');

const toggleActionsBox = () => {
  isActionsBoxVisible.value = !isActionsBoxVisible.value;
};

const handleClickOutside = (event) => {
  if (
    componentRef.value &&
    !componentRef.value.contains(event.target) &&
    !buttonRef.value.contains(event.target)
  ) {
    isActionsBoxVisible.value = false;
  }
};

const copyLink = async () => {
  try {
    let complete = process.env.VUE_APP_BASE_URL + 'app/' + props.survey.slug;
    await navigator.clipboard.writeText(complete);
    copied.value = true;

    setTimeout(() => {
      copied.value = false;
    }, 2000);
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <tr class="text-sm" :class="survey.active ? '' : 'text-gray-400'">
    <td
      class="whitespace-nowrap overflow-hidden text-ellipsis"
      style="max-width: 300px"
    >
      <router-link
        :to="{ name: 'survey-detail', params: { id: props.survey.id } }"
        >{{ props.survey.title }}</router-link
      >
    </td>
    <td class="">
      {{ props.survey.total_questions }}
    </td>
    <td class="">
      {{ props.survey.total_responses }}
    </td>
    <td class="">
      <span v-if="!props.survey.expiration_date">No set expiration</span>
      <span v-if="props.survey.expiration_date">{{
        expirationTime(props.survey.expiration_date)
      }}</span>
    </td>
    <td class="">{{ expirationTime(props.survey.updated_at) }}</td>
    <td class="relative">
      <div class="inline-flex items-center flex-row gap-4">
        <div @click="copyLink()" class="cursor-pointer relative p-[1px]">
          <LinkIcon />
          <transition name="fade">
            <div
              v-if="copied"
              class="text-sm bg-gray-200 px-2 py-1 rounded-lg top-[110%] absolute shadow-md"
            >
              Copied!
            </div>
          </transition>
        </div>
        <div
          @click="toggleActionsBox()"
          ref="buttonRef"
          class="cursor-pointer relative p-[1px]"
        >
          <EllipsisIcon />
        </div>
        <transition name="fade">
          <div
            ref="componentRef"
            v-if="isActionsBoxVisible"
            class="absolute right-[100%] mt-[3px] px-4 py-2 bg-gray-200 text-black rounded-md z-50 flex gap-4 shadow-md"
          >
            <router-link
              v-if="props.survey.active"
              :to="{
                name: 'survey-slug',
                params: { slug: props.survey.slug },
              }"
            >
              <EyePositiveIcon />
            </router-link>
            <EyeNegativeIcon
              class="text-gray-400"
              v-if="!props.survey.active"
            />
            <router-link
              :to="{
                name: 'survey-detail',
                params: { id: props.survey.id },
              }"
            >
              <PencilIcon />
            </router-link>
            <button
              class="cursor-pointer"
              @click="emit('delete', props.survey)"
              type="button"
            >
              <TrashIcon />
            </button>
          </div>
        </transition>
      </div>
    </td>
  </tr>
</template>

<style scoped>
td {
  padding: 0.75rem 1rem;
}
.fade-leave-active,
.fade-enter-active {
  transition: opacity 0.2s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
